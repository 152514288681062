@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon.eot?9epn8x');
  src:  url('assets/fonts/icomoon.eot?9epn8x#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.woff2?9epn8x') format('woff2'),
    url('assets/fonts/icomoon.ttf?9epn8x') format('truetype'),
    url('assets/fonts/icomoon.woff?9epn8x') format('woff'),
    url('assets/fonts/icomoon.svg?9epn8x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-third-party:before {
  content: "\ea4f";
}
.icon-tr401x:before {
  content: "\ea4e";
}
.icon-user:before {
  content: "\ea4d";
}
.icon-all-water-img .path1:before {
  content: "\e900";
  color: rgb(227, 81, 95);
}
.icon-all-water-img .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(62, 137, 191);
}
.icon-all-water:before {
  content: "\e902";
}
.icon-commercial-water-img .path1:before {
  content: "\e903";
  color: rgb(102, 102, 102);
}
.icon-commercial-water-img .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(62, 137, 191);
}
.icon-commercial-water:before {
  content: "\e905";
}
.icon-electric:before {
  content: "\e906";
}
.icon-gas:before {
  content: "\e907";
}
.icon-runtime:before {
  content: "\e908";
}
.icon-thermal:before {
  content: "\e909";
}
.icon-water:before {
  content: "\e90a";
}
.icon-contact-card:before {
  content: "\e90b";
}
.icon-user-A:before {
  content: "\e90c";
}
.icon-masq:before {
  content: "\e90d";
}
.icon-user-AC:before {
  content: "\e90e";
}
.icon-user-B:before {
  content: "\e90f";
}
.icon-user-check:before {
  content: "\e910";
}
.icon-user-D:before {
  content: "\e911";
}
.icon-user-E:before {
  content: "\e912";
}
.icon-user-minus:before {
  content: "\e913";
}
.icon-user-MR:before {
  content: "\e914";
}
.icon-user-P:before {
  content: "\e915";
}
.icon-user-plus:before {
  content: "\e916";
}
.icon-user-T:before {
  content: "\e917";
}
.icon-user-x:before {
  content: "\e918";
}
.icon-users:before {
  content: "\e919";
}
.icon-facebook:before {
  content: "\e91a";
}
.icon-github:before {
  content: "\e91b";
}
.icon-message-circle:before {
  content: "\e91c";
}
.icon-message-square:before {
  content: "\e91d";
}
.icon-slack:before {
  content: "\e91e";
}
.icon-thumbs-down:before {
  content: "\e91f";
}
.icon-thumbs-up:before {
  content: "\e920";
}
.icon-twitter:before {
  content: "\e921";
}
.icon-activity:before {
  content: "\e922";
}
.icon-arrow-sort:before {
  content: "\e923";
}
.icon-award:before {
  content: "\e924";
}
.icon-barcode:before {
  content: "\e925";
}
.icon-building:before {
  content: "\e926";
}
.icon-calendar:before {
  content: "\e927";
}
.icon-cloud-scanner:before {
  content: "\e928";
}
.icon-credit-card:before {
  content: "\e929";
}
.icon-dev:before {
  content: "\e92a";
}
.icon-ethernet:before {
  content: "\e92b";
}
.icon-face-id:before {
  content: "\e92c";
}
.icon-filter:before {
  content: "\e92d";
}
.icon-flash-light:before {
  content: "\e92e";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-globe:before {
  content: "\e930";
}
.icon-health:before {
  content: "\e931";
}
.icon-home:before {
  content: "\e932";
}
.icon-info:before {
  content: "\e933";
}
.icon-key:before {
  content: "\e934";
}
.icon-keyboard:before {
  content: "\e935";
}
.icon-layout:before {
  content: "\e936";
}
.icon-mail:before {
  content: "\e937";
}
.icon-map-pin:before {
  content: "\e938";
}
.icon-map:before {
  content: "\e939";
}
.icon-more:before {
  content: "\e93a";
}
.icon-mra:before {
  content: "\e93b";
}
.icon-phone:before {
  content: "\e93c";
}
.icon-property:before {
  content: "\e93d";
}
.icon-pulse:before {
  content: "\e93e";
}
.icon-radio:before {
  content: "\e93f";
}
.icon-search:before {
  content: "\e940";
}
.icon-settings:before {
  content: "\e941";
}
.icon-tag:before {
  content: "\e942";
}
.icon-touch-id:before {
  content: "\e943";
}
.icon-waves:before {
  content: "\e944";
}
.icon-check-circle:before {
  content: "\e945";
}
.icon-check:before {
  content: "\e946";
}
.icon-check-square:before {
  content: "\e947";
}
.icon-circle:before {
  content: "\e948";
}
.icon-edit-2:before {
  content: "\e949";
}
.icon-edit:before {
  content: "\e94a";
}
.icon-external-link:before {
  content: "\e94b";
}
.icon-eye-off:before {
  content: "\e94c";
}
.icon-eye:before {
  content: "\e94d";
}
.icon-minus-circle:before {
  content: "\e94e";
}
.icon-minus-square:before {
  content: "\e94f";
}
.icon-minus:before {
  content: "\e950";
}
.icon-plus-circle:before {
  content: "\e951";
}
.icon-plus-multiple:before {
  content: "\e952";
}
.icon-plus-square:before {
  content: "\e953";
}
.icon-plus:before {
  content: "\e954";
}
.icon-pull-out-menu:before {
  content: "\e955";
}
.icon-push-in-menu:before {
  content: "\e956";
}
.icon-refresh-ccw:before {
  content: "\e957";
}
.icon-refresh-cw:before {
  content: "\e958";
}
.icon-square:before {
  content: "\e959";
}
.icon-toggle-left:before {
  content: "\e95a";
}
.icon-toggle-right:before {
  content: "\e95b";
}
.icon-trash-2:before {
  content: "\e95c";
}
.icon-trash:before {
  content: "\e95d";
}
.icon-x-circle:before {
  content: "\e95e";
}
.icon-x-square:before {
  content: "\e95f";
}
.icon-x:before {
  content: "\e960";
}
.icon-airplay:before {
  content: "\e961";
}
.icon-align-center:before {
  content: "\e962";
}
.icon-alert-octagon:before {
  content: "\e963";
}
.icon-align-justify:before {
  content: "\e964";
}
.icon-align-left:before {
  content: "\e965";
}
.icon-align-right:before {
  content: "\e966";
}
.icon-anchor:before {
  content: "\e967";
}
.icon-aperture:before {
  content: "\e968";
}
.icon-arrow-up-right:before {
  content: "\e969";
}
.icon-at-sign:before {
  content: "\e96a";
}
.icon-bank:before {
  content: "\e96b";
}
.icon-battery-1:before {
  content: "\e96c";
}
.icon-battery-2:before {
  content: "\e96d";
}
.icon-battery-3:before {
  content: "\e96e";
}
.icon-battery-4:before {
  content: "\e96f";
}
.icon-battery-charging:before {
  content: "\e970";
}
.icon-battery:before {
  content: "\e971";
}
.icon-bell-off:before {
  content: "\e972";
}
.icon-bell:before {
  content: "\e973";
}
.icon-bluetooth:before {
  content: "\e974";
}
.icon-book:before {
  content: "\e975";
}
.icon-bookmark:before {
  content: "\e976";
}
.icon-box:before {
  content: "\e977";
}
.icon-briefcase:before {
  content: "\e978";
}
.icon-burger:before {
  content: "\e979";
}
.icon-camera-off:before {
  content: "\e97a";
}
.icon-camera:before {
  content: "\e97b";
}
.icon-cast:before {
  content: "\e97c";
}
.icon-chrome:before {
  content: "\e97d";
}
.icon-cloud-drizzle:before {
  content: "\e97e";
}
.icon-cloud-lightning:before {
  content: "\e97f";
}
.icon-cloud-off:before {
  content: "\e980";
}
.icon-cloud-rain:before {
  content: "\e981";
}
.icon-cloud-snow:before {
  content: "\e982";
}
.icon-cloud:before {
  content: "\e983";
}
.icon-command:before {
  content: "\e984";
}
.icon-compass:before {
  content: "\e985";
}
.icon-copy:before {
  content: "\e986";
}
.icon-crosshair:before {
  content: "\e987";
}
.icon-delete:before {
  content: "\e988";
}
.icon-disc:before {
  content: "\e989";
}
.icon-download-cloud:before {
  content: "\e98a";
}
.icon-download:before {
  content: "\e98b";
}
.icon-edit-3:before {
  content: "\e98c";
}
.icon-fast-forward:before {
  content: "\e98d";
}
.icon-feather:before {
  content: "\e98e";
}
.icon-film:before {
  content: "\e98f";
}
.icon-flag:before {
  content: "\e990";
}
.icon-grad-cap:before {
  content: "\e991";
}
.icon-grid:before {
  content: "\e992";
}
.icon-hash:before {
  content: "\e993";
}
.icon-headphones:before {
  content: "\e994";
}
.icon-heart:before {
  content: "\e995";
}
.icon-help-circle:before {
  content: "\e996";
}
.icon-inbox:before {
  content: "\e997";
}
.icon-info1:before {
  content: "\e998";
}
.icon-instagram:before {
  content: "\e999";
}
.icon-layers:before {
  content: "\e99a";
}
.icon-life-buoy:before {
  content: "\e99b";
}
.icon-link-2:before {
  content: "\e99c";
}
.icon-link:before {
  content: "\e99d";
}
.icon-loader:before {
  content: "\e99e";
}
.icon-lock:before {
  content: "\e99f";
}
.icon-log-in:before {
  content: "\e9a0";
}
.icon-log-out:before {
  content: "\e9a1";
}
.icon-maximize-2:before {
  content: "\e9a2";
}
.icon-maximize:before {
  content: "\e9a3";
}
.icon-menu:before {
  content: "\e9a4";
}
.icon-mic-off:before {
  content: "\e9a5";
}
.icon-mic:before {
  content: "\e9a6";
}
.icon-minimize-2:before {
  content: "\e9a7";
}
.icon-minimize:before {
  content: "\e9a8";
}
.icon-moon:before {
  content: "\e9a9";
}
.icon-more-horizontal:before {
  content: "\e9aa";
}
.icon-more-vertical:before {
  content: "\e9ab";
}
.icon-move:before {
  content: "\e9ac";
}
.icon-music:before {
  content: "\e9ad";
}
.icon-navigation-2:before {
  content: "\e9ae";
}
.icon-navigation:before {
  content: "\e9af";
}
.icon-octagon:before {
  content: "\e9b0";
}
.icon-package:before {
  content: "\e9b1";
}
.icon-pause-circle:before {
  content: "\e9b2";
}
.icon-pause:before {
  content: "\e9b3";
}
.icon-percent:before {
  content: "\e9b4";
}
.icon-phone-call:before {
  content: "\e9b5";
}
.icon-phone-forwarded:before {
  content: "\e9b6";
}
.icon-phone-incoming:before {
  content: "\e9b7";
}
.icon-phone-missed:before {
  content: "\e9b8";
}
.icon-phone-off:before {
  content: "\e9b9";
}
.icon-phone-outgoing:before {
  content: "\e9ba";
}
.icon-play-circle:before {
  content: "\e9bb";
}
.icon-play:before {
  content: "\e9bc";
}
.icon-pocket:before {
  content: "\e9bd";
}
.icon-power:before {
  content: "\e9be";
}
.icon-printer:before {
  content: "\e9bf";
}
.icon-repeat:before {
  content: "\e9c0";
}
.icon-rewind:before {
  content: "\e9c1";
}
.icon-rotate-ccw:before {
  content: "\e9c2";
}
.icon-rotate-cw:before {
  content: "\e9c3";
}
.icon-save:before {
  content: "\e9c4";
}
.icon-scissors:before {
  content: "\e9c5";
}
.icon-server:before {
  content: "\e9c6";
}
.icon-share-2:before {
  content: "\e9c7";
}
.icon-share:before {
  content: "\e9c8";
}
.icon-shield:before {
  content: "\e9c9";
}
.icon-shuffle:before {
  content: "\e9ca";
}
.icon-sidebar:before {
  content: "\e9cb";
}
.icon-skip-back:before {
  content: "\e9cc";
}
.icon-skip-forward:before {
  content: "\e9cd";
}
.icon-slash:before {
  content: "\e9ce";
}
.icon-speaker:before {
  content: "\e9cf";
}
.icon-star:before {
  content: "\e9d0";
}
.icon-stop-circle:before {
  content: "\e9d1";
}
.icon-sun:before {
  content: "\e9d2";
}
.icon-sunrise:before {
  content: "\e9d3";
}
.icon-sunset:before {
  content: "\e9d4";
}
.icon-target:before {
  content: "\e9d5";
}
.icon-trending-down:before {
  content: "\e9d6";
}
.icon-trending-up:before {
  content: "\e9d7";
}
.icon-triangle:before {
  content: "\e9d8";
}
.icon-type:before {
  content: "\e9d9";
}
.icon-umbrella:before {
  content: "\e9da";
}
.icon-unlock:before {
  content: "\e9db";
}
.icon-upload-cloud:before {
  content: "\e9dc";
}
.icon-upload:before {
  content: "\e9dd";
}
.icon-video-off:before {
  content: "\e9de";
}
.icon-video:before {
  content: "\e9df";
}
.icon-voicemail:before {
  content: "\e9e0";
}
.icon-volume-1:before {
  content: "\e9e1";
}
.icon-volume-2:before {
  content: "\e9e2";
}
.icon-volume-x:before {
  content: "\e9e3";
}
.icon-volume:before {
  content: "\e9e4";
}
.icon-watch:before {
  content: "\e9e5";
}
.icon-wind:before {
  content: "\e9e6";
}
.icon-zap:before {
  content: "\e9e7";
}
.icon-zoom-in:before {
  content: "\e9e8";
}
.icon-zoom-out:before {
  content: "\e9e9";
}
.icon-clipboard:before {
  content: "\e9ea";
}
.icon-file-bak .path1:before {
  content: "\e9eb";
  color: rgb(206, 152, 64);
}
.icon-file-bak .path2:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(206, 152, 64);
}
.icon-file-bak .path3:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-csv .path1:before {
  content: "\e9ee";
  color: rgb(107, 154, 71);
}
.icon-file-csv .path2:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(107, 154, 71);
}
.icon-file-csv .path3:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-doc .path1:before {
  content: "\e9f1";
  color: rgb(90, 141, 178);
}
.icon-file-doc .path2:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(90, 141, 178);
}
.icon-file-doc .path3:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-html .path1:before {
  content: "\e9f4";
  color: rgb(68, 68, 68);
}
.icon-file-html .path2:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(68, 68, 68);
}
.icon-file-html .path3:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-jpg .path1:before {
  content: "\e9f7";
  color: rgb(90, 141, 178);
}
.icon-file-jpg .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(90, 141, 178);
}
.icon-file-jpg .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-minus:before {
  content: "\e9fa";
}
.icon-file-pdf .path1:before {
  content: "\e9fb";
  color: rgb(167, 49, 60);
}
.icon-file-pdf .path2:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(167, 49, 60);
}
.icon-file-pdf .path3:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-plus:before {
  content: "\e9fe";
}
.icon-file-png .path1:before {
  content: "\e9ff";
  color: rgb(90, 141, 178);
}
.icon-file-png .path2:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(90, 141, 178);
}
.icon-file-png .path3:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-ppt .path1:before {
  content: "\ea02";
  color: rgb(167, 49, 60);
}
.icon-file-ppt .path2:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(167, 49, 60);
}
.icon-file-ppt .path3:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-sdf .path1:before {
  content: "\ea05";
  color: rgb(206, 152, 64);
}
.icon-file-sdf .path2:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(206, 152, 64);
}
.icon-file-sdf .path3:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-tar .path1:before {
  content: "\ea08";
  color: rgb(179, 169, 53);
}
.icon-file-tar .path2:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(179, 169, 53);
}
.icon-file-tar .path3:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-text:before {
  content: "\ea0b";
}
.icon-file-txt .path1:before {
  content: "\ea0c";
  color: rgb(68, 68, 68);
}
.icon-file-txt .path2:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(68, 68, 68);
}
.icon-file-txt .path3:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-xls .path1:before {
  content: "\ea0f";
  color: rgb(107, 154, 71);
}
.icon-file-xls .path2:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(107, 154, 71);
}
.icon-file-xls .path3:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-zip .path1:before {
  content: "\ea12";
  color: rgb(179, 169, 53);
}
.icon-file-zip .path2:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(179, 169, 53);
}
.icon-file-zip .path3:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file:before {
  content: "\ea15";
}
.icon-history:before {
  content: "\ea16";
}
.icon-image:before {
  content: "\ea17";
}
.icon-cpu:before {
  content: "\ea18";
}
.icon-dc301:before {
  content: "\ea19";
}
.icon-gw201:before {
  content: "\ea1a";
}
.icon-gw301:before {
  content: "\ea1b";
}
.icon-meter:before {
  content: "\ea1c";
}
.icon-monitor:before {
  content: "\ea1d";
}
.icon-rr301:before {
  content: "\ea1e";
}
.icon-repeater:before {
  content: "\ea1f";
}
.icon-router:before {
  content: "\ea20";
}
.icon-smartphone:before {
  content: "\ea21";
}
.icon-tablet:before {
  content: "\ea22";
}
.icon-third-party-cable:before {
  content: "\ea23";
}
.icon-third-party-es:before {
  content: "\ea24";
}
.icon-third-party-fa:before {
  content: "\ea25";
}
.icon-transceiver-open:before {
  content: "\ea26";
}
.icon-transceiver:before {
  content: "\ea27";
}
.icon-wifi:before {
  content: "\ea28";
}
.icon-bar-chart-2:before {
  content: "\ea29";
}
.icon-bar-chart-fair:before {
  content: "\ea2a";
}
.icon-bar-chart-weak:before {
  content: "\ea2b";
}
.icon-bar-chart:before {
  content: "\ea2c";
}
.icon-cellular-off:before {
  content: "\ea2d";
}
.icon-pie-chart:before {
  content: "\ea2e";
}
.icon-arrow-down-left:before {
  content: "\ea2f";
}
.icon-corner-right-up:before {
  content: "\ea30";
}
.icon-corner-up-right:before {
  content: "\ea31";
}
.icon-corner-down-left:before {
  content: "\ea32";
}
.icon-chevron-down:before {
  content: "\ea33";
}
.icon-chevron-right:before {
  content: "\ea34";
}
.icon-arrow-left:before {
  content: "\ea35";
}
.icon-chevrons-up:before {
  content: "\ea36";
}
.icon-arrow-down-right:before {
  content: "\ea37";
}
.icon-chevron-up:before {
  content: "\ea38";
}
.icon-arrow-up:before {
  content: "\ea39";
}
.icon-corner-up-left:before {
  content: "\ea3a";
}
.icon-chevrons-left:before {
  content: "\ea3b";
}
.icon-corner-left-down:before {
  content: "\ea3c";
}
.icon-chevrons-right:before {
  content: "\ea3d";
}
.icon-chevron-left:before {
  content: "\ea3e";
}
.icon-arrow-right:before {
  content: "\ea3f";
}
.icon-corner-left-up:before {
  content: "\ea40";
}
.icon-corner-right-down:before {
  content: "\ea41";
}
.icon-arrow-up-left:before {
  content: "\ea42";
}
.icon-arrow-down:before {
  content: "\ea43";
}
.icon-chevrons-down:before {
  content: "\ea44";
}
.icon-corner-down-right:before {
  content: "\ea45";
}
.icon-alert-circle:before {
  content: "\ea46";
}
.icon-alert-triangle:before {
  content: "\ea47";
}
.icon-battery-low:before {
  content: "\ea48";
}
.icon-freeze:before {
  content: "\ea49";
}
.icon-leak-B:before {
  content: "\ea4a";
}
.icon-power-loss:before {
  content: "\ea4b";
}
.icon-thermometer:before {
  content: "\ea4c";
}
