@import './variables.scss';
@import './colors.scss';
@import './icons.css';

@font-face {
  font-family: 'Lato-regular';
  src: local('Lato-regular'), url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-light';
  src: local('Lato-light'), url(./assets/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-thin';
  src: local('Lato-thin'), url(./assets/fonts/Lato-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-bold';
  src: local('Lato-bold'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-bolder';
  src: local('Lato-bolder'), url(./assets/fonts/Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-light-italic';
  src: local('Lato-light-italic'), url(./assets/fonts/Lato-LightItalic.ttf) format('truetype');
}
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.large-variable-text {
  font-size: 35pt;
  font-family: 'Lato-regular';
  letter-spacing: -0.02em;
  font-style: normal;
  line-height: normal;
}

.medium-variable-text {
  font-size: 22pt;
  font-family: 'Lato-regular';
  letter-spacing: -0.02em;
  font-style: normal;
  line-height: normal;
}

.xlarge-variable-text {
  font-size: 48pt;
  font-family: 'Lato-regular';
  letter-spacing: 0.12em;
  font-style: normal;
  line-height: normal;
}

@media screen and (max-width: $sm-screen-max) {
  .large-variable-text {
    font-size: 10vw;
  }
  .medium-variable-text {
    font-size: 6vw;
  }
  .xlarge-variable-text {
    font-size: 12vw;
  }
}


.blue-gradient {
  background: linear-gradient(78.75deg, $dark-blue -10.55%, $light-blue 106.05%);
}

.gray-gradient {
  background: linear-gradient(180deg, $background-gradient-gray, $background-gradient-white);
}

.white-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(196, 196, 196, 0.15) 100%);
}

.btn-primary {
  border: none;
  border-radius: 48px;
  background: $light-blue;
  width: 100%;
  height: 47px;
}

.btn-lg {
  font-size: 1.07rem;
}

.tooltip-link {
  color: $medium-blue !important;
  cursor: pointer;
  position: relative;
  &:hover {
    .tip {
      opacity: 1;
      z-index: 4;
    }
  }
  .tip {
    .italic {
      font-style: italic;
    }
    padding: 15px;
    z-index: -4;
    line-height: normal;
    word-spacing: normal;
    font-size: 10pt;
    color: $medium-gray;
    width: 330px;
    height: 190px;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);
    border-radius: 6px;
  }
}

.btn-primary:hover {
  color: #fff;
  background-color: $dark-blue;
  border: none;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  border: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #5887b7;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5385b3;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border: none;
}

@media screen and (max-width: $sm-screen-max){
  .large-variable-text {
    font-size: 25pt;
  }
}
