$blue-gray: #506269;
$background-dark: #071C25;
$light-gray: #C4C4C4;
$light-border: #E0E0E0;
$very-light-gray: #F5F5F5;
$medium-gray: #787B7C;
$dark-gray: #444444;
$green: #4AA346;
$light-blue: #0BADCF;
$dark-blue: #054E82;
$medium-blue: #007AB6;
$background-gradient-gray: #c7cfd1;
$background-gradient-white: #f0f0f0;

$blue-dark: #2F4758;
$blue-gray: #526D80;
$blue: #3E89BF;
$indigo: #754BC1;
$purple: #A043CF;
$purple-dark: #573C59;
$purple-gray: #7B597E;
$purple-light: #dfd3e0;
$pink: #E45898;
$red: #E3515F;
$red-light: #e8b3b9;
$red-dark: #ce4552;
$red-leak: #ad313d;
$orange: #F1AC3A;
$yellow: #e6d82b;
$yellow-dark: #cebf10;
$green: #7BC147;
$teal: #83EFA3;
$cyan: #15c2d8;
$white: #FFFFFF;
$gray-xxlight: #cfcfcf;
$gray-xslight: #c4c4c4;
$gray-xlight: #b5b5b5;
$gray-light: #999999;
$gray: #666;
$gray-dark: #444;